import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { Body2, BodyM, TextXS } from '@salutejs/plasma-web';
import { Dropzone } from 'components';
import { fontWeights, primary, secondary, tertiary } from '@salutejs/plasma-tokens-b2b';
import { Accept } from 'react-dropzone';
import { Icon } from '@salutejs/plasma-icons';
import { h5Bold } from '@salutejs/plasma-typo';
import { mobileMediaQueries } from 'utils';

const StyledContainer = styled.div`
    display: flex;
    flex-flow: column;
`;

const StyledUploadHeader = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const StyledUploadTitle = styled(Body2)`
    color: ${primary};
    font-weight: ${fontWeights.Semibold};
`;

const StyledUploadHeaderDesc = styled.span`
    color: ${tertiary};
    font-size: 14px;
    font-weight: ${fontWeights.Regular};
    line-height: 18px;
    letter-spacing: -0.266px;
`;

const StyledUploadContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
`;

const StyledUploadText = styled(Body2)`
    color: ${primary};
    margin-bottom: 8px;

    ${mobileMediaQueries(css`
        ${h5Bold}
    `)}
`;

const StyledUploadDesktopText = styled.span`
    ${mobileMediaQueries(css`
        display: none;
    `)}
`;

const StyledDownloadAll = styled.div`
    color: #226af1;
    cursor: pointer;
`;

const StyledBlue = styled.span`
    color: #226af1;
`;

const StyledUploadDesc = styled(TextXS)`
    color: ${secondary};
    text-align: center;
    padding: 0 19px;
`;

const uploadText = (
    <StyledUploadContainer>
        <StyledUploadText>
            <StyledBlue>Загрузите файлы</StyledBlue>{' '}
            <StyledUploadDesktopText>или перетащите его сюда</StyledUploadDesktopText>
        </StyledUploadText>
        <StyledUploadDesc>Формат файла — jpg, png и bmp. Размер файла не более 250 Кб</StyledUploadDesc>
    </StyledUploadContainer>
);

const StyledFile = styled.div`
    display: flex;
    height: 48px;
    padding: 0 12px;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid ${tertiary};
    margin-top: 16px;
`;

const StyledFileIcon = styled.div`
    width: 24px;
    height: 24px;
    background: url('/assets/icons/document.svg') no-repeat center/contain;
`;

const StyledFileName = styled(BodyM)`
    color: ${primary};
    margin-right: auto;
`;

const StyledFileRemove = styled.div`
    cursor: pointer;
`;

const StyledFileRemoveIcon = styled(Icon)`
    display: block;

    & svg {
        color: ${secondary};
    }
`;

interface FileItemProps {
    file: File;
    onRemove?(file: File): void;
    onDownload?(file: File): void;
}

const FileItem: React.FC<FileItemProps> = ({ file, onRemove, onDownload }) => {
    const fileRemoveHandle = useCallback(() => onRemove?.(file), [file, onRemove]);
    const fileDownloadHandle = useCallback(() => onDownload?.(file), [file, onDownload]);

    return (
        <StyledFile>
            <StyledFileIcon />
            <StyledFileName>{file.name}</StyledFileName>
            {onRemove && (
                <StyledFileRemove onClick={fileRemoveHandle}>
                    <StyledFileRemoveIcon icon="close" />
                </StyledFileRemove>
            )}
            {onDownload && (
                <StyledFileRemove onClick={fileDownloadHandle}>
                    <StyledFileRemoveIcon icon="download" />
                </StyledFileRemove>
            )}
        </StyledFile>
    );
};

const filesAccept: Accept = { 'image/bmp': ['.bmp'], 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'] };

interface PlacementStandFileListProps {
    canRemove?: boolean;
    onDownload?(files: File[]): void;
}

export const PlacementStandFileList: React.FC<PlacementStandFileListProps> = ({ canRemove, onDownload }) => {
    const [files, setFiles] = useState<File[]>([]);

    const filesChangeHandle = useCallback(
        (newFiles: File[]) => setFiles((currentFiles) => [...currentFiles, ...newFiles]),
        [],
    );

    const fileRemoveHandle = useCallback(
        (file: File) => setFiles((currentFiles) => currentFiles.filter((item) => item !== file)),
        [],
    );

    const filesDownloadHandle = useCallback(() => onDownload?.(files ?? []), [files, onDownload]);

    const fileDownloadHandle = useCallback((file: File) => onDownload?.([file]), [onDownload]);

    return (
        <StyledContainer>
            <StyledUploadHeader>
                <StyledUploadTitle>
                    Скриншоты со статистикой <StyledUploadHeaderDesc>опционально</StyledUploadHeaderDesc>
                </StyledUploadTitle>
                {Boolean(files.length && onDownload) && (
                    <StyledDownloadAll onClick={filesDownloadHandle}>Скачать все</StyledDownloadAll>
                )}
            </StyledUploadHeader>
            <Dropzone onChange={filesChangeHandle} accept={filesAccept}>
                {uploadText}
            </Dropzone>
            {files.map((item) => (
                <FileItem
                    file={item}
                    onRemove={canRemove !== false ? fileRemoveHandle : undefined}
                    onDownload={onDownload ? fileDownloadHandle : undefined}
                />
            ))}
        </StyledContainer>
    );
};
