import styled, { css } from 'styled-components';
import { Footnote1 } from '@salutejs/plasma-web';
import { secondary, white } from '@salutejs/plasma-tokens-b2b';
import { FC, useContext } from 'react';
import { useRouter } from 'next/router';
import { bodySBold } from '@salutejs/plasma-typo';
import { Container } from 'components';
import Link from 'next/link';

import { AuthContext } from '@/store/AuthContext';

const StyledContainer = styled(Container)`
    flex-flow: row;
    align-items: center;
    height: 100%;
`;

const Wrap = styled.header`
    background-color: ${white};
    z-index: 3;
    position: sticky;
    top: 0;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));
    height: 56px;

    & a {
        text-decoration: none;
    }
`;

const ExitWrap = styled.div`
    cursor: pointer;
    height: 56px;
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 4px;
    margin-left: auto;
`;

const Exit = styled(Footnote1)`
    color: ${secondary};
    font-weight: 600;
`;

const StyledNav = styled.div`
    display: flex;
    flex-flow: row;
    height: 100%;
    margin-left: 24px;
`;

const StyledNavItem = styled(Link)<{ $active: boolean }>`
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 100%;
    padding: 0 16px;
    color: ${secondary};
    ${bodySBold}

    ${({ $active }) =>
        $active &&
        css`
            color: #2a72f8;
            box-shadow: inset 0 -2px 0 #2a72f8;
        `}
`;

export interface HeaderNavItem {
    label: string;
    url: string;
}

interface HeaderProps {
    nav?: HeaderNavItem[];
}

export const Header: FC<HeaderProps> = ({ nav }) => {
    const { logout } = useContext(AuthContext);
    const router = useRouter();

    return (
        <Wrap>
            <StyledContainer>
                <img src="/assets/icons/logo.svg" alt="logo" />
                {nav && (
                    <StyledNav>
                        {nav.map((item) => (
                            <StyledNavItem
                                key={item.label}
                                href={item.url}
                                $active={router.pathname.indexOf(item.url) === 0}
                            >
                                {item.label}
                            </StyledNavItem>
                        ))}
                    </StyledNav>
                )}

                <ExitWrap onClick={logout}>
                    <Exit>Выйти</Exit>
                    <img src="/assets/icons/logout.svg" alt="logout" />
                </ExitWrap>
            </StyledContainer>
        </Wrap>
    );
};
