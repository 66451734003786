import { surfaceSolid01, blackTertiary } from '@salutejs/plasma-tokens-b2b';
import { css, FlattenSimpleInterpolation } from 'styled-components';
import { StandardLonghandPropertiesHyphen } from 'csstype';
import { CSSProperties } from 'react';

export const dividerBorder: CSSProperties['border'] = `1px solid ${blackTertiary}`;

export const transitionEasingDuration: CSSProperties['transition'] = '200ms ease-in-out';

export const applyTransition = (
    ...properties: Array<keyof StandardLonghandPropertiesHyphen>
): FlattenSimpleInterpolation => {
    const arr = properties.length ? properties : ['all'];

    return css`
        transition: ${arr.map((p) => `${p} ${transitionEasingDuration}`)};
    `;
};

export const applyHoverAccent = (): FlattenSimpleInterpolation => {
    return css`
        ${applyTransition('background-color')}

        &:hover {
            background-color: ${surfaceSolid01};
        }
    `;
};

export const applyOverlay = (position: CSSProperties['position'] = 'absolute'): FlattenSimpleInterpolation => {
    return css`
        position: ${position};
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    `;
};

export const applyInvisible = (): FlattenSimpleInterpolation => {
    return css`
        visibility: hidden;
        pointer-events: none;
    `;
};
