import { useEffect } from 'react';

export const useWindowEvent = (
    eventName: keyof WindowEventMap,
    callback: (event: Event) => void,
    options?: boolean | AddEventListenerOptions,
): void => {
    useEffect(() => {
        window.addEventListener(eventName, callback, options);

        return () => window.removeEventListener(eventName, callback);
    }, [callback, eventName, options]);
};
