import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { H4, Radiobox, RadioGroup, TextField } from '@salutejs/plasma-web';
import { PlacementStand, PlacementStatisticAttachmentType, PublisherOffer } from 'types';
import { PlacementStandFileList } from 'components/PlacementStandFileList';
import { UseFormRegister, UseFormTrigger } from 'react-hook-form/dist/types/form';
import { Icon } from '@salutejs/plasma-icons';
import { tertiary } from '@salutejs/plasma-tokens-b2b';

import { mobileMediaQueries } from '@/utils';

const StyledContainer = styled.div`
    display: flex;
    flex-flow: column;
    gap: 24px;

    ${mobileMediaQueries(css`
        gap: 20px;
    `)}
`;

const StyledTitle = styled(H4)`
    position: relative;
`;

const StyledClose = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
`;

const StyledRadioGroup = styled(RadioGroup)`
    display: flex;
    flex-flow: row;
    gap: 24px;

    ${mobileMediaQueries(css`
        flex-flow: column;
        gap: 12px;
    `)}
`;

interface PlacementStandInfoProps {
    remove: (index: number) => void;
    register: UseFormRegister<Partial<PublisherOffer>>;
    trigger: UseFormTrigger<Partial<PublisherOffer>>;
    stand: Partial<PlacementStand>;
    index: number;
    showIndex?: boolean;
    canRemove?: boolean;
}

export const PlacementStandInfo: React.FC<PlacementStandInfoProps> = ({
    register,
    remove,
    trigger,
    stand,
    index,
    showIndex,
    canRemove,
}) => {
    const resetHandle = useCallback(() => canRemove && remove(index), [canRemove, index, remove]);

    return (
        <StyledContainer>
            <StyledTitle>
                Площадка {showIndex ? index + 1 : ''}
                {canRemove && (
                    <StyledClose onClick={resetHandle}>
                        <Icon icon="close" color={tertiary} />
                    </StyledClose>
                )}
            </StyledTitle>
            <TextField
                label="URL сайта"
                placeholder="Введите адрес сайта"
                helperText="Адрес главной страницы"
                {...register(`placementStands.${index}.url`, { required: true, shouldUnregister: true })}
                onBlur={() => trigger(`placementStands.${index}.url`)}
            />
            <StyledRadioGroup>
                <Radiobox
                    label="URL статистики"
                    {...register(`placementStands.${index}.statisticAttachmentType`, {
                        required: true,
                        shouldUnregister: true,
                    })}
                    value={PlacementStatisticAttachmentType.Url}
                />
                <Radiobox
                    label="Скриншоты со статистикой"
                    {...register(`placementStands.${index}.statisticAttachmentType`, {
                        required: true,
                        shouldUnregister: true,
                    })}
                    value={PlacementStatisticAttachmentType.Files}
                />
            </StyledRadioGroup>
            {stand.statisticAttachmentType === PlacementStatisticAttachmentType.Url && (
                <TextField
                    label="URL статистики"
                    placeholder="Введите адрес статистики"
                    helperText="Адрес общедоступной статистики сайта (напр. liveinternet.ru/stat/site или similarweb.com/ru/website/site)"
                    {...register(`placementStands.${index}.statisticUrl`, { required: true, shouldUnregister: true })}
                    onBlur={() => trigger(`placementStands.${index}.statisticUrl`)}
                />
            )}
            {stand.statisticAttachmentType === PlacementStatisticAttachmentType.Files && <PlacementStandFileList />}
        </StyledContainer>
    );
};
