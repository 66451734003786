import { handleException } from '@/utils';

export type Options<T> = Omit<RequestInit, 'body'> & { body?: T; toJson?: boolean };
export const makeRequest = <R, T = {}>(url: string, options: Options<T> = {}): Promise<R> => {
    const { toJson = true, headers, ...requestOptions } = options;

    const init = {
        headers: {
            'Content-Type': 'application/json;charset=utf-8"',
            ...headers,
        },
        method: 'GET',
        ...requestOptions,
        ...(requestOptions?.body ? { body: JSON.stringify(requestOptions.body) } : {}),
    };

    return fetch(url, init as RequestInit)
        .then((res) => {
            if (res.status >= 400 && res.status < 600) {
                throw res.statusText;
            }

            if (toJson) {
                return res.json();
            }

            return res;
        })
        .catch((err) => {
            handleException(err);
            throw err;
        });
};
