import React, { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { User } from 'types';
import { usersEntryPages } from 'const';

import { authorize, getUser, logout as globalLogout } from '../api/auth';

interface AuthState {
    user: User | null;
    login(email: string, password: string): Promise<void>;
    logout(): Promise<void>;
}

const initialState: AuthState = {
    user: null,
    login: Promise.reject,
    logout: Promise.reject,
};

export const AuthContext = React.createContext(initialState);

interface AuthContextProviderProps {
    user: User | null;
    children: React.ReactNode;
}

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ user: serverUser, children }) => {
    const [user, setUser] = useState<AuthState['user']>(serverUser ?? null);
    const router = useRouter();

    const login = useCallback<AuthState['login']>(
        async (email, password) => {
            await authorize({ email, password });
            const newUser = await getUser();
            setUser(newUser);
            await router.push(usersEntryPages[newUser.role]);
        },
        [router],
    );

    const logout = useCallback<AuthState['logout']>(async () => {
        await globalLogout();
        setUser(null);
        await router.push('/auth');
    }, [router]);

    const state: AuthState = useMemo(() => ({ user, login, logout }), [user, login, logout]);

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
