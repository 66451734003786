import styled, { css } from 'styled-components';
import { Property } from 'csstype';
import { applySpacing, SpacingProps } from '@salutejs/plasma-web';

export const maxWidth = 1920;
export const minWidth = 1200;

export type FlexContainerProps = {
    alignItems?: Property.AlignItems;
    flexDirection?: Property.FlexDirection;
    justifyContent?: Property.JustifyContent;
    flexWrap?: Property.FlexWrap;
    isFlexGrow?: boolean;
    // постоянный размер отступов в rem
    spacing?: number;
    // размеры отступов при minWidth в rem
    minSpacing?: number;
    // размеры отступов при maxWidth в rem
    maxSpacing?: number;
    // по сколько элементов в одном ряду
    by?: number;
    byM?: number;
} & SpacingProps;

const calculateSpacing = (props: FlexContainerProps) => {
    if (props.spacing) return props.spacing;

    if (props.minSpacing && props.maxSpacing) {
        return (
            props.minSpacing +
            ((props.maxSpacing - props.minSpacing) / (maxWidth - minWidth)) *
                (globalThis.screen?.width || maxWidth - minWidth)
        );
    }

    return null;
};

const getValues = (props: FlexContainerProps) => {
    const { alignItems, justifyContent, flexDirection, flexWrap, isFlexGrow } = props;
    let values = '';

    values = alignItems ? `${values}; align-items: ${alignItems}` : values;
    values = justifyContent ? `${values}; justify-content: ${justifyContent}` : values;
    values = flexDirection ? `${values}; flex-direction: ${flexDirection}` : values;
    values = flexWrap ? `${values}; flex-wrap: ${flexWrap}` : values;
    values = isFlexGrow !== undefined ? `${values}; flex-grow: ${Number(isFlexGrow)}` : values;

    return values;
};

const getSpacings = (props: FlexContainerProps) => {
    const spacing = calculateSpacing(props);

    if (spacing == null) return '';

    return css`
        gap: ${spacing}rem;
    `;
};

const getChildrenWidth = (props: FlexContainerProps) => {
    const getWidth = (count?: number) => {
        if (!count || count < 2) {
            return '';
        }

        let width = `${100 / count}%`;

        const spacing = calculateSpacing(props);

        if (spacing != null) {
            width = `calc((100% - ${spacing * (count - 1)}rem) / ${count})`;
        }

        return width;
    };

    return css`
        /* stylelint-disable-next-line selector-max-universal */
        & > * {
            width: ${getWidth(props.by)};

            @media (max-width: ${maxWidth - 1}px) {
                width: ${getWidth(props.byM)};
            }
        }
    `;
};

export const FlexContainer = styled.div<FlexContainerProps>`
    display: flex;
    ${getValues};
    ${getSpacings};
    ${getChildrenWidth};
    ${applySpacing};
`;
