import React from 'react';
import styled from 'styled-components';
import { BodyM, Select as PlasmaSelect, SelectProps as PlasmaSelectProps } from '@salutejs/plasma-web';
import { primary } from '@salutejs/plasma-tokens-b2b';

const StyledSelectCaption = styled(BodyM)`
    margin-bottom: 10px;
    color: ${primary};
`;

export const SelectRoot = styled.div`
    & > div:not(${StyledSelectCaption}) > div {
        display: block;
    }

    & button {
        --plasma-dropdown-border-radius: 12px !important;
        border-radius: var(--plasma-dropdown-border-radius);
    }
`;

interface SelectProps {
    caption?: string;
    label?: string;
    className?: string;
}

export const Select: React.FC<SelectProps & PlasmaSelectProps> = ({ caption, label, className, ...props }) => {
    return (
        <SelectRoot className={className}>
            {!!(caption || label) && <StyledSelectCaption>{caption || label}</StyledSelectCaption>}
            <PlasmaSelect {...props} />
        </SelectRoot>
    );
};
