import { useCallback, useRef } from 'react';
import throttle from 'lodash.throttle';

import { useWindowEvent } from './useWindowEvent';

interface Size {
    width: number;
    height: number;
}

export const useWindowResize = (
    callback: (event: Event, lastSize: Size) => void,
    options?: boolean | AddEventListenerOptions,
): void => {
    const lastSize = useRef<Size>({
        width: typeof window !== 'undefined' ? window.innerWidth : 0,
        height: typeof window !== 'undefined' ? window.innerHeight : 0,
    });

    const listener = useCallback(
        (event) =>
            throttle(() => {
                callback(event, lastSize.current);
                lastSize.current = {
                    width: window.innerWidth,
                    height: window.innerHeight,
                };
            })(),
        [callback],
    );

    useWindowEvent('resize', listener, options);
};
