import { useRouter } from 'next/router';

export const useParams = (): Record<string, string> => {
    const router = useRouter();
    const routerPath = (router.pathname ?? '').split('/');
    const originPath: string[] = (typeof window !== 'undefined' ? window.location.pathname : router.pathname).split(
        '/',
    );
    const paramRegExp = /\[([^\]]+)]/;

    return routerPath.reduce<Record<string, string>>((res, item, index) => {
        if (!paramRegExp.test(item)) {
            return res;
        }

        const key = item.replace(paramRegExp, '$1');
        res[key] = originPath[index + (router.locale !== router.defaultLocale ? 1 : 0)];

        return res;
    }, {});
};
