import React, { useCallback, useState } from 'react';
import { Button, H3, Modal, TextField } from '@salutejs/plasma-web';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
    width: 548px !important;
`;

const StyledInput = styled(TextField)`
    width: 484px;
    margin-top: 16px;
`;

const StyledButtons = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: end;
    gap: 12px;
    margin-top: 36px;
`;

interface PublisherThemeAddModalProps {
    open: boolean;
    onAdd(theme: string): void;
    onClose(): void;
}

export const PublisherThemeAddModal: React.FC<PublisherThemeAddModalProps> = ({ open, onAdd, onClose }) => {
    const [theme, setTheme] = useState<string>();

    const changeHandle = useCallback((event) => setTheme(event.target.value), []);

    const addHandle = useCallback(() => theme && onAdd(theme), [onAdd, theme]);

    return (
        <StyledModal isOpen={open} onClose={onClose} withBlur={false}>
            <H3>Добавить новую тематику</H3>
            <StyledInput label="Новая тематика" placeholder="Введите тематику" value={theme} onChange={changeHandle} />
            <StyledButtons>
                <Button onClick={onClose}>Отменить</Button>
                <Button view="primary" disabled={!theme} onClick={addHandle}>
                    Сохранить
                </Button>
            </StyledButtons>
        </StyledModal>
    );
};
