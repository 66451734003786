import React, { useEffect, useState, useCallback } from 'react';
import { useWindowResize } from 'hooks';
import { Breakpoint, gridBreakpoints } from '@salutejs/plasma-web';

export type GridContextModel = Breakpoint | null;

export const initialContext: GridContextModel = null;

export const GridContext = React.createContext<GridContextModel>(initialContext);

export const GridContextProvider: React.FC = ({ children }) => {
    const [breakpoint, setBreakpoint] = useState<GridContextModel>(null);

    const resizeHandle = useCallback(() => {
        const currentBreakpoint = (Object.entries(gridBreakpoints).find(
            (entries) => window.innerWidth >= entries[1],
        )?.[0] ?? null) as GridContextModel;

        if (breakpoint === currentBreakpoint) {
            return;
        }

        setBreakpoint(currentBreakpoint);
    }, [breakpoint]);

    useEffect(() => {
        const currentBreakpoint = (Object.entries(gridBreakpoints).find(
            (entries) => window.innerWidth >= entries[1],
        )?.[0] ?? null) as GridContextModel;

        setBreakpoint(currentBreakpoint);
    }, []);

    useWindowResize(resizeHandle);

    return <GridContext.Provider value={breakpoint}>{children}</GridContext.Provider>;
};
