import { UserRole } from '@/types';

export const isDev = process.env.NODE_ENV === 'development';
export const isClient = typeof window !== 'undefined';
export const currentStandCookieName = 'layerAdminCurrentStand';
export const API_DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export const usersEntryPages: Record<UserRole, string> = {
    [UserRole.Publisher]: '/stats',
    [UserRole.PublisherModerator]: '/stats',
    [UserRole.Retailer]: '/stats',
    [UserRole.RetailerModerator]: '/stats',
};
