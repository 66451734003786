import { WebTelemetryMonitoringWeb } from '@sberdevices/web-telemetry/lib/presets/WebTelemetryMonitoringWeb';

export const getTelemetry = (): WebTelemetryMonitoringWeb => {
    return WebTelemetryMonitoringWeb.Instance({
        projectName: 'layer-admin-partners',
        debug: process.env.WORKFLOW_NAME === 'local' || !process.env.WORKFLOW_NAME,
        frameTime: true,
        resourcesBlackList: [/assets\/video\/auth\.mp4$/],
    });
};
