import React, { useContext, useMemo } from 'react';
import { GridContext } from 'store';
import { Breakpoint, gridBreakpoints } from '@salutejs/plasma-web';

export const Responsive: React.FC<Partial<Record<Breakpoint | 'default', React.ReactNode>>> = (breakpoints) => {
    const breakpoint = useContext(GridContext);

    return useMemo(() => {
        const breakpointSize = Object.entries(gridBreakpoints).find((entry) => entry[0] === breakpoint)?.[1] ?? null;

        if (breakpointSize === null) {
            return <>{breakpoints.default ?? null}</>;
        }

        const newBreakpoint: Breakpoint | null =
            (Object.entries(gridBreakpoints).find(
                ([key, value]) => breakpoints[key as Breakpoint] && value <= breakpointSize,
            )?.[0] as Breakpoint) ?? null;
        const component: React.ReactNode = breakpoints[newBreakpoint ?? 'default'] ?? null;

        return <>{component}</>;
    }, [breakpoint, breakpoints]);
};
