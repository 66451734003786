import { API_PERIOD } from 'api/constants';

export type Nullable<T> = T | null;

export enum AppStand {
    prom = 'prom',
    ift = 'ift',
}

export type CatalogItem = { id: number; name: string };

export interface ChartRequestParamsType {
    period?: API_PERIOD;
    dateFrom?: string;
    dateTo?: string;
    partners?: string;
    categories?: string;
    csv?: boolean;
}

export interface ChartResultDataType {
    date: string;
    site_name: string;
    value: string;
}

export interface APIResponse {
    count: number;
    next: string;
    previous: string;
    aggregated_value: number;
    results: ChartResultDataType[];
}

export interface StatsFetchParams {
    period?: API_PERIOD;
    dateFrom?: string;
    dateTo?: string;
    partners?: string;
    categories?: string;
    ordering?: string;
    csv?: boolean;
}
