import { TableCol } from '@sberdevices/layer-admin-components';
import { SelectProps } from '@salutejs/plasma-web';
import { CompanyType, PlacementType, PublisherStatus, PublisherTheme } from 'types';

export const publishersTableColumns: TableCol[] = [
    { label: 'Организация', field: 'name' },
    { label: 'Наименование', field: 'companyName' },
    { label: 'Тематика', field: 'themes' },
    { label: 'Дата заявки', field: 'createDate' },
    { label: 'Статус', field: 'status' },
];

export const publisherThemes: SelectProps['items'] = [
    { label: 'Мода', value: PublisherTheme.Fashion },
    { label: 'Новости', value: PublisherTheme.News },
];

export const publisherStatuses: SelectProps['items'] = [
    { label: 'Новая заявка', value: PublisherStatus.New },
    { label: 'Отклонено', value: PublisherStatus.Rejected },
    { label: 'Согласовано', value: PublisherStatus.Resolved },
    { label: 'Запущено', value: PublisherStatus.Run },
];

export const companyTypes: SelectProps['items'] = [
    { label: 'ООО', value: CompanyType.OOO },
    { label: 'ОАО', value: CompanyType.OAO },
    { label: 'ЗАО', value: CompanyType.ZAO },
    { label: 'Иная', value: CompanyType.Other },
];

export const placementTypesList: SelectProps['items'] = [
    { label: 'Все', value: PlacementType.All },
    { label: 'Widget', value: PlacementType.Widget },
    { label: 'Banner', value: PlacementType.Banner },
    { label: 'Outstream', value: PlacementType.Outstream },
];

export const standRejectionReasonsList: SelectProps['items'] = [
    { label: 'Недостаточная посещаемость сайта', value: 'Недостаточная посещаемость сайта' },
    { label: 'Сайт не работает', value: 'Сайт не работает' },
    { label: 'Сайт не соответствует правилам участия', value: 'Сайт не соответствует правилам участия' },
    { label: 'Другое', value: 'Другое' },
];
