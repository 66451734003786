import React, { useMemo } from 'react';
import styled from 'styled-components';
import { BodyM, Button, H3 } from '@salutejs/plasma-web';
import { Container, HeaderNavItem, Layout, PublisherOfferNavItem } from 'components';
import { Icon } from '@salutejs/plasma-icons';
import { buttonAccent, fontWeights } from '@salutejs/plasma-tokens-b2b';
import { useRouter } from 'next/router';
import { useParams } from 'hooks';
import { PublisherAcceptNav } from 'components/PublisherAcceptNav';

const StyledHeader = styled.div`
    padding: 32px 0 0;
    margin-bottom: 20px;
    border-bottom: solid 1px #e8e8e8;
`;

const StyledTitle = styled(H3)`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    max-width: 980px;
`;

const StyledDesc = styled(BodyM)`
    margin-bottom: 32px;
    margin-top: 4px;
`;

const StyledBold = styled.span`
    font-weight: ${fontWeights.Semibold};
`;

const StyledBackButton = styled(Button)`
    padding: 0;
    color: ${buttonAccent};
    margin-top: -12px;
    margin-right: auto;
    justify-content: start;

    & svg {
        color: ${buttonAccent};
    }
`;

const nav: HeaderNavItem[] = [
    { label: 'Акцепт площадок', url: '/publisher-accept' },
    { label: 'Статистика', url: '/statistic' },
];

const navItems: (PublisherOfferNavItem & { backUrl?: string })[] = [
    {
        label: 'Данные огранизации',
        url: '/publisher-accept/[id]/company-info',
        backUrl: '/publisher-accept',
    },
    {
        label: 'Форматы и данные площадок',
        url: '/publisher-accept/[id]/coordinate-launch',
        backUrl: '/publisher-accept',
    },
    {
        label: 'Изменить логин и/или пароль',
        url: '/publisher-accept/[id]/auth-data',
        backUrl: '/publisher-accept',
    },
];

interface PublisherAcceptLayoutProps {
    children?: React.ReactNode;
    title?: string;
}

export const PublisherAcceptLayout: React.FC<PublisherAcceptLayoutProps> = ({ children, title }) => {
    const router = useRouter();
    const { id } = useParams();
    const activeRoute = useMemo(() => navItems.find((item) => item.url === router.pathname), [router.pathname]);

    return (
        <Layout nav={nav}>
            <StyledHeader>
                <Container>
                    {activeRoute?.backUrl && (
                        <StyledBackButton
                            view="clear"
                            size="m"
                            contentLeft={<Icon icon="arrowLeft" size="s" />}
                            text="Назад"
                            onClick={() => router.push({ pathname: activeRoute.backUrl?.replace('[id]', id) })}
                        />
                    )}
                    <StyledTitle>{title}</StyledTitle>
                    <StyledDesc>
                        Для одобрения или отклонения заявок, перейдите в раздел{' '}
                        <StyledBold>Форматы и данные площадок</StyledBold>
                    </StyledDesc>
                    <PublisherAcceptNav items={navItems} />
                </Container>
            </StyledHeader>

            <Container>{children}</Container>
        </Layout>
    );
};
