import React from 'react';
import styled from 'styled-components';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

const StyledContainer = styled.div<{ isDragActive: boolean }>`
    display: block;
    height: 112px;
    background: #fff;
    border: 2px dashed ${({ isDragActive }) => (isDragActive ? '#226af1' : 'rgba(8, 8, 8, 0.28)')};
    border-radius: 4px;
    cursor: pointer;
    /* stylelint-disable plugin/no-low-performance-animation-properties */
    transition: border 0.1s linear;

    &:hover {
        border-color: #226af1;
    }
`;

interface DropzoneProps extends Omit<DropzoneOptions, 'onDrop'> {
    children?: React.ReactNode;
    className?: string;
    onChange(files: File[]): void;
}

export const Dropzone: React.FC<DropzoneProps> = ({ children, className, onChange, ...props }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onChange, ...props });

    return (
        <StyledContainer {...getRootProps({ className })} isDragActive={isDragActive}>
            <input {...getInputProps()} />
            {children}
        </StyledContainer>
    );
};
