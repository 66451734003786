export enum UserRole {
    Retailer = 'retailer',
    RetailerModerator = 'retailer_moderator',
    Publisher = 'publisher',
    PublisherModerator = 'publisher_moderator',
}

export interface User {
    id: string;
    email: string;
    is_active: boolean;
    is_superuser: boolean;
    is_verified: boolean;
    name: string;
    legal_name: string;
    role: UserRole;
}
