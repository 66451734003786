import { NextApiRequest } from 'next/dist/shared/lib/utils';
import { currentStandCookieName } from 'const';
import { User } from 'types';

import { API_URL, getApiBaseUrl } from './constants';

export const getUser = (): Promise<User> => {
    return fetch(`${API_URL}/users/me`).then(async (response) => {
        if (response.status >= 400) {
            throw Error(`Authorization error, code: ${response.status}`);
        }

        return response.json();
    });
};

export const getServerUser = (req: NextApiRequest): Promise<User> => {
    const BASE_URL = getApiBaseUrl(req.cookies[currentStandCookieName]);

    return fetch(`${BASE_URL}/users/me`, {
        credentials: 'include',
        headers: {
            Cookie: req.headers.cookie as string,
        },
    }).then(async (response) => {
        if (response.status >= 400) {
            throw Error(`Authorization error, code: ${response.status}`);
        }

        return response.json();
    });
};

export const authorize = async (formData: { email: string; password: string }): Promise<boolean> => {
    const res = await fetch(`${API_URL}/users/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
    });

    return res.status < 400;
};

export const logout = async (): Promise<void> => {
    return fetch(`${API_URL}/users/logout`, {
        method: 'POST',
    }).then((response) => {
        if (response.status >= 400) {
            throw Error(`Logout error, code: ${response.status}`);
        }
    });
};
