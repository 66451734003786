import { useState, useCallback, useMemo } from 'react';

export interface UseBoolResult {
    value: boolean;
    on(): void;
    off(): void;
    toggle(): void;
}

export const useBool = (initValue = false): UseBoolResult => {
    const [value, setValue] = useState(initValue);
    const on = useCallback(() => setValue(true), []);
    const off = useCallback(() => setValue(false), []);
    const toggle = useCallback(() => setValue((currentValue) => !currentValue), []);

    return useMemo(() => ({ value, on, off, toggle }), [value, on, off, toggle]);
};
