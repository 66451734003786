import styled, { css } from 'styled-components';
import { white, headline3, secondary, accent } from '@salutejs/plasma-tokens-b2b';
import { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Container } from 'components';

const StyledContainer = styled.div`
    background-color: ${white};
    border-bottom: 1px solid #e8e8e8;
`;

const CentredWrap = styled(Container)`
    flex-flow: row;
    grid-gap: 24px;
`;

const StyledLink = styled(Link)<{ $active: boolean; $disabled?: boolean }>`
    text-decoration: none;
    padding: 32px 0 8px;
    color: ${({ $active }) => ($active ? accent : secondary)};
    box-shadow: ${({ $active }) => ($active ? `inset 0 -2px 0 0 ${accent}` : 'none')};
    ${headline3}

    ${({ $disabled }) =>
        $disabled &&
        css`
            color: rgba(8, 8, 8, 0.28);
            cursor: default;
            pointer-events: none;
        `};
`;

interface Props {
    isVisualSearch?: boolean;
}

export const NavTabs: FC<Props> = () => {
    const router = useRouter();

    return (
        <StyledContainer>
            <CentredWrap>
                <StyledLink href="/stats" $active={router.pathname === '/stats'}>
                    Shoppable Widget
                </StyledLink>
                <StyledLink href="/stats/smart-banner" $active={router.pathname === '/stats/smart-banner'}>
                    Smart Banner
                </StyledLink>
            </CentredWrap>
        </StyledContainer>
    );
};
