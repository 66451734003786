import styled from 'styled-components';
import React from 'react';
import { H3, TextM } from '@salutejs/plasma-web';

const StyledContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
`;

const StyledIcon = styled.img`
    width: 64px;
    height: 64px;
    margin-bottom: 20px;
    display: block;
`;

const StyledTitle = styled(H3)``;

const StyledDesc = styled(TextM)`
    margin-top: 12px;
    max-width: 450px;
`;

interface NotFoundBannerProps {
    title: string;
    desc?: string;
}

export const NotFoundBanner: React.FC<NotFoundBannerProps> = ({ title, desc }) => {
    return (
        <StyledContainer>
            <StyledIcon src="/assets/icons/banner-not-found.svg" />
            <StyledTitle>{title}</StyledTitle>
            {desc && <StyledDesc>{desc}</StyledDesc>}
        </StyledContainer>
    );
};
