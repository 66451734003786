type Options = {
    expires?: Date | number | string;
    'max-age'?: number | string;
    path?: string;
    domain?: string;
    secure?: boolean;
};

type Name = string | number | boolean;
type Value = string | number | boolean;

class Cookie {
    static get(name: string) {
        const matches = document.cookie.match(
            new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`),
        );

        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    static set(name: Name, value: Value, options: Options = {}) {
        options = {
            path: '/',
            ...options,
        };

        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }

        let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

        const optionsKeys = Object.keys(options);
        for (const optionKey of optionsKeys) {
            updatedCookie += `; ${optionKey}`;
            const optionValue = options[optionKey as keyof typeof options];

            if (optionValue !== true) {
                updatedCookie += `=${optionValue}`;
            }
        }

        document.cookie = updatedCookie;
    }
}

export function setCookie(name: Name, value: Value, options?: Options) {
    Cookie.set(name, value, options);
}

export function getCookie(name: string) {
    return Cookie.get(name);
}

export const deleteCookie = (name: Name) => {
    setCookie(name, '', {
        'max-age': -1,
    });
};
