import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { SWRConfig } from 'swr';
import { NotificationsProvider } from '@salutejs/plasma-web';
import { GlobalStyle } from 'components';
import { makeRequest as fetcher } from 'api/makeRequest';
import { setDefaultOptions } from 'date-fns';
import { ru } from 'date-fns/locale';
import { getTelemetry } from 'utils';
import Head from 'next/head';
import { AuthContextProvider, GridContextProvider, FeaturesContextProvider } from 'store';

setDefaultOptions({ locale: ru });

export function reportWebVitals(metric: NextWebVitalsMetric) {
    const telemetry = getTelemetry();

    telemetry.KV.push({
        key: metric.name,
        value: Math.round(metric.value),
    });
}

export default function App({ Component, pageProps }: AppProps) {
    return (
        <NotificationsProvider>
            <SWRConfig
                value={{
                    fetcher,
                    revalidateOnFocus: false,
                }}
            >
                <Head>
                    <title>Layer Partners</title>
                </Head>
                <GlobalStyle />
                <GridContextProvider>
                    <AuthContextProvider user={pageProps.user}>
                        <FeaturesContextProvider>
                            <Component {...pageProps} />
                        </FeaturesContextProvider>
                    </AuthContextProvider>
                </GridContextProvider>
            </SWRConfig>
        </NotificationsProvider>
    );
}
