import React from 'react';
import styled, { css } from 'styled-components';
import { Container } from '@salutejs/plasma-web';
import { Logo } from 'components';
import { mobileMediaQueries } from 'utils';

import { PublisherOfferNav, PublisherOfferNavItem } from './PublisherOfferNav';
import { StandSwitcher } from './StandSwitcher';

const StyledContainer = styled(Container)`
    padding-top: 64px;
    padding-bottom: 64px;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;

    ${mobileMediaQueries(css`
        padding-top: 32px;
        padding-bottom: 32px;
    `)}
`;

const StyledLogo = styled(Logo)`
    ${mobileMediaQueries(css`
        width: 100px;
        height: 26px;
    `)}
`;

const StyledNav = styled(PublisherOfferNav)`
    margin: 32px 0;

    ${mobileMediaQueries(css`
        margin: 28px 0;
    `)}
`;

const navItems: PublisherOfferNavItem[] = [
    { label: 'Выбор форматов и добавление площадок', url: '/publisher-offer/site-info' },
    { label: 'Регистрация нового паблишера', url: '/publisher-offer/registration' },
    { label: 'Готово', url: '/publisher-offer/done' },
];

export const PublisherOfferLayout: React.FC = ({ children }) => {
    return (
        <StyledContainer>
            <StyledLogo />
            <StyledNav items={navItems} />
            {children}
            <StandSwitcher />
        </StyledContainer>
    );
};
