import React, { ChangeEventHandler, useCallback, useMemo, useState } from 'react';
import { TextField, TextFieldProps } from '@salutejs/plasma-web';
import debounce from 'lodash.debounce';

interface DebouncedFieldProps extends Omit<TextFieldProps, 'onChange'> {
    value?: string;
    timeout?: number;
    onChange?(value: string): void;
    onInputStart?(): void;
    onInputEnd?(): void;
}

export const DebouncedField: React.FC<DebouncedFieldProps> = ({
    value,
    timeout = 1500,
    onChange,
    onInputStart,
    onInputEnd,
    ...props
}) => {
    const [localValue, setLocalValue] = useState<string | undefined>(value);

    const debouncedChange = useMemo(
        () =>
            debounce((event) => {
                onChange?.(event);
                onInputEnd?.();
            }, timeout),
        [onChange, onInputEnd, timeout],
    );

    const changeHandle = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            const newValue = event.target.value;

            onInputStart?.();
            setLocalValue(newValue);
            debouncedChange?.(newValue);
        },
        [debouncedChange, onInputStart],
    );

    return <TextField {...props} value={localValue} onChange={changeHandle} />;
};
