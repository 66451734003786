import { useCallback, useMemo, useState } from 'react';
import { PublisherOffer } from 'types';

const lsKey = 'publisher-offer';

interface PublisherOfferHookResult {
    offer: Partial<PublisherOffer>;
    update(offer: Partial<PublisherOffer>): void;
}

export const usePublisherOffer = (): PublisherOfferHookResult => {
    const [offer, setOffer] = useState<PublisherOfferHookResult['offer']>(
        typeof window !== 'undefined' ? JSON.parse(localStorage?.getItem(lsKey) ?? '{}') : {},
    );

    const update = useCallback<PublisherOfferHookResult['update']>(
        (newOffer) =>
            setOffer((oldOffer) => {
                const newState = { ...oldOffer, ...newOffer };

                localStorage.setItem(lsKey, JSON.stringify(newState));

                return newState;
            }),
        [],
    );

    return useMemo<PublisherOfferHookResult>(() => ({ offer, update }), [offer, update]);
};
