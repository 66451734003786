export const getQueryParams = (): Record<string, string | Array<string>> => {
    const searchParams = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '');
    const res: Record<string, string | Array<string>> = {};

    searchParams.forEach((value, key) => {
        if (key in res) {
            if (Array.isArray(res[key])) {
                res[key] = [...(res[key] as Array<string>), value];
            } else {
                res[key] = [res[key] as string, value];
            }
        } else {
            res[key] = value;
        }
    });

    return res;
};

export const useQuery = getQueryParams;
