import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button, H4, Modal, Radiobox, RadioGroup, TextField } from '@salutejs/plasma-web';
import { PlacementStand, PlacementStandStatus, PlacementStatisticAttachmentType, PublisherOffer } from 'types';
import { PlacementStandFileList } from 'components/PlacementStandFileList';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';

const StyledContainer = styled.div`
    display: flex;
    flex-flow: column;
    gap: 24px;
`;

const StyledTitle = styled(H4)`
    position: relative;
`;

const StyledRadioGroup = styled(RadioGroup)`
    display: flex;
    flex-flow: row;
    gap: 24px;

    & > div {
        cursor: default;
    }
`;

const StyledButtons = styled.div`
    display: flex;
    flex-flow: row;
    gap: 16px;

    & > button {
        width: calc(50% - 8px);
    }
`;

interface PlacementStandInfoApproveProps {
    register: UseFormRegister<Partial<PublisherOffer>>;
    stand: Partial<PlacementStand>;
    index: number;
    onResolve(index: number): void;
    onReject(index: number): void;
}

export const PlacementStandInfoApprove: React.FC<PlacementStandInfoApproveProps> = ({
    register,
    stand,
    index,
    onResolve,
    onReject,
}) => {
    const resolveHandle = useCallback(() => onResolve(index), [index, onResolve]);
    const rejectHandle = useCallback(() => onReject(index), [index, onReject]);

    return (
        <StyledContainer>
            <StyledTitle>Площадка {index + 1}</StyledTitle>

            <TextField
                label="URL сайта"
                placeholder="Введите адрес сайта"
                helperText="Адрес главной страницы"
                {...register(`placementStands.${index}.url`, { required: true, shouldUnregister: true })}
            />

            <StyledRadioGroup>
                <Radiobox
                    label="URL статистики"
                    {...register(`placementStands.${index}.statisticAttachmentType`, {
                        required: true,
                        shouldUnregister: true,
                    })}
                    value={PlacementStatisticAttachmentType.Url}
                />
                <Radiobox
                    label="Скриншоты со статистикой"
                    {...register(`placementStands.${index}.statisticAttachmentType`, {
                        required: true,
                        shouldUnregister: true,
                    })}
                    value={PlacementStatisticAttachmentType.Files}
                />
            </StyledRadioGroup>

            {stand.statisticAttachmentType === PlacementStatisticAttachmentType.Url && (
                <TextField
                    label="URL статистики"
                    placeholder="Введите адрес статистики"
                    helperText="Адрес общедоступной статистики сайта (напр. liveinternet.ru/stat/site или similarweb.com/ru/website/site)"
                    {...register(`placementStands.${index}.statisticUrl`, { required: true, shouldUnregister: true })}
                />
            )}

            {stand.statisticAttachmentType === PlacementStatisticAttachmentType.Files && (
                <PlacementStandFileList onDownload={() => {}} />
            )}

            {(stand.status === PlacementStandStatus.New || !stand.status) && (
                <StyledButtons>
                    <Button onClick={rejectHandle}>Отклонить</Button>
                    <Button view="primary" onClick={resolveHandle}>
                        Одобрить
                    </Button>
                </StyledButtons>
            )}

            {stand.status === PlacementStandStatus.Resolved && (
                <StyledButtons>
                    <Button view="primary" disabled>
                        Заявка одобрена
                    </Button>
                </StyledButtons>
            )}

            {stand.status === PlacementStandStatus.Rejected && (
                <StyledButtons>
                    <Button disabled>Заявка отклонена</Button>
                </StyledButtons>
            )}
        </StyledContainer>
    );
};
