export const emailRegExp = /^[^@]+@.+\.\w+$/;
export const phoneRegExp = /^(\+\d{0,4}|8)\s?\(?\d{3}\)?\s?\d{3}\s?\d{2}(-|\s)?\d{2}$/;
export const kppRegExp = /^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/;
export const innRegExp = /^(\d{10}|\d{12})$/;
export const ogrnRegExp = /^\d{13}$/;
export const ogrnIPRegExp = /^\d{15}$/;
export const bikRegExp = /^\d{9}$/;
export const rsRegExp = /^\d{20}$/;
export const ksRegExp = /^\d{20}$/;
export const dateRegExp = /^(\d{2})\.(\d{2})\.(\d{4})$/;
export const indexRegExp = /^\d{6}$/;
export const passportNumberRegExp = /^\d{2}\s?\d{2}\s?\d{6}$/;

export const validateDate: (value: string) => boolean = (value) => {
    if (!value || !dateRegExp.test(value)) {
        return false;
    }

    const date = new Date(value.replace(dateRegExp, '$3/$2/$1'));
    const daysPerMonthCount = new Date(+value.replace(dateRegExp, '$3'), +value.replace(dateRegExp, '$2'), 0).getDate();

    if (+value.replace(dateRegExp, '$1') < 1) {
        return false;
    }

    if (daysPerMonthCount < +value.replace(dateRegExp, '$1')) {
        return false;
    }

    return !Number.isNaN(date.getTime());
};
