import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useQuery } from 'hooks/useQuery';

type UpdateValue = string | number | boolean | Array<string | number | boolean>;
type UpdateQuery = { [key: string]: UpdateValue };
type Update = (query: UpdateQuery) => Promise<void>;
type UpdateParam = (value: UpdateValue) => Promise<void>;

export const useUpdateQuery = (): Update => {
    const router = useRouter();
    const query = useQuery();

    return useCallback(
        async (newQuery) => {
            const preparedQuery = Object.entries(newQuery).reduce<{ [key: string]: string | string[] }>(
                (acc, [key, value]) => {
                    const queryValue = Array.isArray(value) ? value.map((item) => item.toString()) : value.toString();

                    return { ...acc, [key]: queryValue };
                },
                {},
            );

            await router.push({ pathname: router.pathname, query: { ...query, ...preparedQuery } });
        },
        [query, router],
    );
};

export const useUpdateQueryParam = (name: string): UpdateParam => {
    const update = useUpdateQuery();

    return useCallback((value) => update({ [name]: value }), [name, update]);
};
