export type Stand = 'unknown' | 'local' | 'pr' | 'dev' | 'ift' | 'prom';

const getStand = (): Stand => {
    const workflowName = process.env.NEXT_PUBLIC_WORKFLOW_NAME;
    const serviceEnvironment = process.env.NEXT_PUBLIC_SERVICE_ENVIRONMENT;

    if (process.env.NODE_ENV === 'development') {
        return 'local';
    }

    if (workflowName === 'pr' && serviceEnvironment === 'dev') {
        return 'pr';
    }

    if (workflowName === 'dev' && serviceEnvironment === 'dev') {
        return 'dev';
    }

    if (workflowName === 'release' && serviceEnvironment === 'ift') {
        return 'ift';
    }

    if (workflowName === 'release') {
        return 'prom';
    }

    return 'unknown';
};

export const appStand = getStand();
