import { createGlobalStyle } from 'styled-components';
import { web } from '@salutejs/plasma-tokens-web/typo';
import { light } from '@salutejs/plasma-tokens-b2b/themes';
import { text } from '@salutejs/plasma-tokens-b2b';
import { standard } from '@salutejs/plasma-typo';

/* stylelint-disable selector-nested-pattern, selector-max-id */
const DocumentStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
        color: ${text};
        font-family: 'SB Sans Text', sans-serif;
    }

    /* stylelint-disable */
    *, *::before, *::after {
        box-sizing: inherit;
        --plasma-colors-input-border: rgba(8, 8, 8, 0.16);
        --plasma-input-border-radius: 12px;
        --plasma-field-label-margin-bottom: 10px;
    }

    html,
    body {
        height: 100%;
        padding: 0;
        margin: 0;
    }

    button, select, input, textarea {
        font-family: inherit;
    }

    #__next {
        min-height: 100%;
    }

    #plasma-notifications-root > div {
        overflow: hidden;
    }

    img {
        display: block;
        max-width: 100%;
    }

    button::-moz-focus-inner {
        border: 0;
    }

    button:active, button:focus {
        outline: none;
    }
`;
/* stylelint-enable */

const ThemeStyle = createGlobalStyle(light);
const TypoStyle = createGlobalStyle(web);
const B2CTypoTheme = createGlobalStyle(standard);

export const GlobalStyle = () => (
    <>
        <ThemeStyle />
        <TypoStyle />
        <B2CTypoTheme />
        <DocumentStyle />
    </>
);
