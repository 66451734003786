var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"_RBQgXU4q9yF_tSId4pxy"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
    dsn: 'https://aa234eb8b0214a75b72246efd168793f@sentry.fine.sberdevices.ru/64',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: [
        'Hydration failed',
        'There was an error while hydrating',
        'Text content does not match server-rendered HTML',
    ],
});
