import ru from 'date-fns/locale/ru';
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { blackSecondary } from '@salutejs/plasma-tokens-b2b';
import { TextField } from '@salutejs/plasma-web';
import { IconDisclosureDown, IconCalendar, IconDisclosureUp } from '@salutejs/plasma-icons';
import { forwardRef, useMemo, useRef, useState } from 'react';
import { FlexContainer } from 'components/FlexContainer';

import { dateFormatter, periodFormat } from '@/utils';

registerLocale('ru', ru);

const IconWrap = styled(FlexContainer)`
    height: 100%;
`;

interface InputProps {
    open: boolean;
    startDate?: Date | null;
    endDate?: Date | null;
    onIconClick(): void;
}

const Input = forwardRef<HTMLInputElement, InputProps>(({ open, startDate, endDate, onIconClick }, ref) => {
    const periodText = useMemo(() => {
        if (!startDate && !endDate) {
            return '';
        }

        if (!startDate && endDate) {
            return dateFormatter.format(endDate).replace(/(\sг?)?\./gi, '');
        }

        if (startDate && !endDate) {
            return dateFormatter.format(startDate).replace(/(\sг?)?\./gi, '');
        }

        return startDate && endDate ? periodFormat(startDate.toISOString(), endDate.toISOString()) : '';
    }, [endDate, startDate]);

    return (
        <TextField
            ref={ref}
            contentRight={
                <IconWrap onClick={onIconClick} justifyContent="center" alignItems="center">
                    {open && <IconDisclosureUp color={blackSecondary} />}
                    {!open && <IconDisclosureDown color={blackSecondary} />}
                </IconWrap>
            }
            value={periodText}
            contentLeft={
                <IconWrap onClick={onIconClick} justifyContent="center" alignItems="center">
                    <IconCalendar color={blackSecondary} />
                </IconWrap>
            }
        />
    );
});

export const UIDatepicker = (props: ReactDatePickerProps) => {
    const ref = useRef<DatePicker | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const onIconClick = () => {
        if (ref.current && !props.readOnly) {
            ref.current.setOpen(true);
        }
    };

    return (
        <>
            <DatePicker
                ref={ref}
                dateFormat="d MMM EEEEEE"
                dropdownMode="select"
                locale="ru"
                maxDate={new Date()}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                selectsRange
                customInput={
                    <Input
                        open={isOpen}
                        startDate={props.startDate}
                        endDate={props.endDate}
                        onIconClick={onIconClick}
                    />
                }
                {...props}
                onCalendarOpen={() => {
                    setIsOpen(true);
                    props.onCalendarOpen?.();
                }}
                onCalendarClose={() => {
                    setIsOpen(false);
                    props.onCalendarClose?.();
                }}
            />
            {/* <DatePickerWrapperStyles /> */}
        </>
    );
};
