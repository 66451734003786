import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { BodyM } from '@salutejs/plasma-web';
import { primary, tertiary, paragraph } from '@salutejs/plasma-tokens-b2b';
import { useRouter } from 'next/router';

import { mobileMediaQueries } from '@/utils';

const StyledContainer = styled.div`
    display: flex;
    flex-flow: row;
    overflow: auto;
    grid-gap: 87px;
    counter-reset: item;

    ${mobileMediaQueries(css`
        flex-flow: column;
        grid-gap: 20px;
    `)}
`;

const StyledItem = styled(BodyM)<{ $active: boolean; $filled: boolean; $clickable?: boolean }>`
    position: relative;
    display: flex;
    flex-flow: row;
    align-items: center;
    grid-gap: 12px;
    color: ${tertiary};
    counter-increment: item;
    cursor: default;

    &::before {
        content: counter(item);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        color: ${tertiary};
        border-radius: 100%;
        background: #ececec;
    }

    &::after {
        content: ' ';
        position: absolute;
        display: block;
        width: 55px;
        height: 2px;
        left: 100%;
        margin-left: 16px;
        border-radius: 2px;
        background: ${tertiary};
        cursor: default;
    }

    &:last-child::after {
        display: none;
    }

    /* stylelint-disable order/order */
    ${({ $active }) =>
        $active &&
        css`
            color: #226af1;

            &::before {
                color: #fff;
                background: #226af1;
            }
        `}

    ${({ $filled }) =>
        $filled &&
        css`
            color: ${paragraph};
            cursor: pointer;

            &::before {
                color: ${primary};
                background: #ececec;
            }
        `}

    ${({ $clickable }) =>
        $clickable === false &&
        css`
            cursor: default;
        `}

    ${mobileMediaQueries(css`
        &::after {
            width: 2px;
            height: 12px;
            left: 0;
            top: 100%;
            margin-top: 4px;
            margin-left: 14px;
        }
    `)}
`;

const StyledItemText = styled.div`
    width: calc(100% - 42px);
`;

export interface PublisherOfferNavItem {
    label: string;
    url: string;
    clickable?: boolean;
}

interface PublisherOfferNavProps {
    items: PublisherOfferNavItem[];
}

export const PublisherOfferNav: React.FC<PublisherOfferNavProps> = ({ items, ...props }) => {
    const router = useRouter();
    const activeIndex = useMemo(() => items.findIndex((item) => item.url === router.pathname), [
        items,
        router.pathname,
    ]);

    return (
        <StyledContainer {...props}>
            {items.map((item, index) => (
                <StyledItem
                    key={item.label}
                    $active={item.url === router.pathname}
                    $filled={index < activeIndex}
                    $clickable={item.clickable}
                    bold
                    onClick={() => item.clickable !== false && index < activeIndex && router.push(item.url)}
                >
                    <StyledItemText>{item.label}</StyledItemText>
                </StyledItem>
            ))}
        </StyledContainer>
    );
};
