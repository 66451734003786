import React, { useCallback } from 'react';
import { TextField, TextFieldProps } from '@salutejs/plasma-web';
import InputMask from 'react-input-mask';

interface MaskedFieldProps extends Omit<TextFieldProps, 'onChange'> {
    value?: string;
    mask: string;
    maskChar?: string;
    alwaysShowMask?: boolean;
    onChange?(value: string): void;
}

export const MaskedField: React.FC<MaskedFieldProps> = ({
    value,
    mask,
    maskChar,
    alwaysShowMask,
    onChange,
    onBlur,
    ...props
}) => {
    const changeHandle = useCallback((event) => onChange?.(event.target.value), [onChange]);

    return (
        <InputMask
            mask={mask}
            maskChar={maskChar ?? ''}
            alwaysShowMask={alwaysShowMask}
            value={value}
            onChange={changeHandle}
            onBlur={onBlur}
        >
            {(maskProps: any) => <TextField {...maskProps} {...props} />}
        </InputMask>
    );
};
