import { useEffect } from 'react';

export const useOnClickOutside = (element: HTMLElement | undefined | null, handler: () => void): void => {
    useEffect(() => {
        const listener = (event: any): void => {
            if (element && element.contains(event.target)) {
                event.stopPropagation();

                return;
            }

            handler();
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [element, handler]);
};
