import React from 'react';
import styled, { css } from 'styled-components';
import { fontWeights, secondary } from '@salutejs/plasma-tokens-b2b';
import { useRouter } from 'next/router';
import { Footnote2 } from '@salutejs/plasma-web';
import { useParams } from 'hooks';

const StyledContainer = styled.div`
    display: flex;
    flex-flow: row;
    overflow: auto;
    grid-gap: 24px;
    counter-reset: item;
`;

const StyledItem = styled(Footnote2)<{ $active: boolean }>`
    color: ${secondary};
    cursor: pointer;
    font-weight: ${fontWeights.Semibold};
    padding-bottom: 16px;

    ${({ $active }) =>
        $active &&
        css`
            color: #226af1;
            box-shadow: inset 0 -2px 0 0 #226af1;
        `}
`;

export interface PublisherAcceptNavItem {
    label: string;
    url: string;
}

interface PublisherAcceptNavProps {
    items: PublisherAcceptNavItem[];
}

export const PublisherAcceptNav: React.FC<PublisherAcceptNavProps> = ({ items, ...props }) => {
    const router = useRouter();
    const { id } = useParams();

    return (
        <StyledContainer {...props}>
            {items.map((item) => (
                <StyledItem
                    key={item.label}
                    $active={item.url === router.pathname}
                    onClick={() => router.push({ pathname: item.url.replace('[id]', id) })}
                >
                    {item.label}
                </StyledItem>
            ))}
        </StyledContainer>
    );
};
