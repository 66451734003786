import React from 'react';
import styled from 'styled-components';
import { Button, H3, Modal } from '@salutejs/plasma-web';

const StyledModal = styled(Modal)`
    text-align: center;
    width: 560px !important;

    & > button svg {
        color: #c7c7c7;
    }

    & [aria-label='close'] {
        display: none;
    }
`;

const StyledIcon = styled.img`
    width: 100px;
    height: 100px;
    display: block;
    object-fit: contain;
    margin: 0 auto;
`;

const StyledModalTitle = styled(H3)`
    margin: 16px auto;
    hyphens: none;
`;

const StyledDesc = styled.div`
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.304px;
    margin-bottom: 28px;
    hyphens: none;
`;

interface DoneModalProps {
    title: React.ReactNode;
    desc?: React.ReactNode;
    submitText: string;
    open: boolean;
    onClose?(): void;
    onSubmit?(): void;
}

export const DoneModal: React.FC<DoneModalProps> = ({ title, desc, submitText, open, onClose, onSubmit }) => {
    return (
        <StyledModal
            isOpen={open}
            closeButtonAriaLabel={!onClose ? 'close' : undefined}
            withBlur={false}
            onClose={onClose}
        >
            <StyledIcon src="/assets/icons/done-circle.svg" />
            <StyledModalTitle>{title}</StyledModalTitle>
            {desc && <StyledDesc>{desc}</StyledDesc>}
            <Button view="primary" onClick={onSubmit}>
                {submitText}
            </Button>
        </StyledModal>
    );
};
