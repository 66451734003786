// TODO: add tests for this utils when there will be test runner in project

import { showErrorNotification } from 'components/Notifications';
import { scalingPixelBasis } from '@salutejs/plasma-tokens-b2b';
import { currentStandCookieName, isClient, isDev } from 'const';
import { AppStand, Nullable } from 'types';

import { getCookie, deleteCookie } from '@/cookie';

export * from './legalDataValidators';
export * from './numberWithSpaces';
export * from './stand';
export * from './telemetry';
export * from './desktopMediaQueries';
export * from './mobileMediaQueries';
export * from './periodFormat';

export function isPromDeployedApp(): boolean {
    if (isDev || !isClient) {
        return false;
    }

    const match = window.location.host.match(/^.+\.(.+)\.app\.sberdevices\.ru/);

    return match?.[1] === AppStand.prom;
}

export function getAppStand(): AppStand {
    if (isPromDeployedApp()) {
        return AppStand.prom;
    }

    const stand = isClient && (getCookie(currentStandCookieName) as Nullable<AppStand>);

    return stand || AppStand.prom;
}

export const logout = () => {
    deleteCookie('token');
};

export const handleException = (err: unknown) => {
    if (err instanceof Error) {
        showErrorNotification(err.message);
    }

    if (typeof err === 'string') {
        showErrorNotification(err);
    }
};

export const serialize = (obj: Record<string, string | number | boolean>) => {
    const str = [];
    for (const p in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(p)) {
            str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
        }
    }

    return str.join('&');
};

export const ucFirst = (str: string) => {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
};

type Values = string | number | null | boolean | undefined | (number | string)[];
type ObjectWithEmptyValues = Record<string, Values>;
type NoUndefinedField<T> = { [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>> };

export const removeEmptyValues = (obj: ObjectWithEmptyValues) => {
    const target: NoUndefinedField<ObjectWithEmptyValues> = {};

    const isEmptyStringValue = (value: Values) => typeof value === 'string' && !value;
    const isUndefinedValue = (value: Values): value is undefined => value === undefined;
    const isEmptyArrayValue = (value: Values) => Array.isArray(value) && value.length === 0;

    const isValid = (value: Values): value is Exclude<Values, undefined> =>
        !isEmptyStringValue(value) && !isUndefinedValue(value) && !isEmptyArrayValue(value);

    for (const key of Object.keys(obj)) {
        if (isValid(obj[key])) {
            // FIXME
            // @ts-ignore
            target[key] = obj[key];
        }
    }

    return target;
};

export const hexToRgba = (hex: string, alpha = 1): string | null => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (!result) {
        return null;
    }

    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export function pxToRem(px: number): string {
    return `${px / scalingPixelBasis}rem`;
}
