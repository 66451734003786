import React, { useContext, useEffect, useState } from 'react';
import { getFeatures } from 'api/features';
import { showErrorNotification } from 'components/Notifications';

import { AuthContext } from '@/store/AuthContext';

type FeaturesState = Record<string, boolean>;

const initialState: Record<string, boolean> = {};

export const FeaturesContext = React.createContext(initialState);

interface FeaturesContextProviderProps {
    children: React.ReactNode;
}

export const FeaturesContextProvider: React.FC<FeaturesContextProviderProps> = ({ children }) => {
    const { user } = useContext(AuthContext);
    const [features, setFeatures] = useState<FeaturesState>(initialState);

    useEffect(() => {
        if (user) {
            getFeatures()
                .then(setFeatures)
                .catch(() => showErrorNotification('Ошибка загрузки фичафлагов'));
        } else {
            setFeatures({});
        }
    }, [user]);

    return <FeaturesContext.Provider value={features}>{children}</FeaturesContext.Provider>;
};
