export enum PublisherOfferPartnershipType {
    Legal = 'legal',
    IndividualEntrepreneur = 'individualEntrepreneur',
    Individual = 'individual',
}

export enum PlacementType {
    All = 'all',
    Widget = 'widget',
    Banner = 'banner',
    Outstream = 'outstream',
}

export enum PublisherTheme {
    Fashion = 'fashion',
    News = 'news',
}

export enum PlacementStatisticAttachmentType {
    Files = 'files',
    Url = 'url',
}

export enum PlacementStandStatus {
    New = 'new',
    Resolved = 'resolved',
    Rejected = 'rejected',
}

export interface PlacementFormat {
    type: PlacementType;
    value?: string;
}

export interface PlacementStand {
    url: string;
    statisticUrl: string;
    theme?: PublisherTheme[];
    legalName: string;
    statisticAttachmentType: PlacementStatisticAttachmentType;
    status: PlacementStandStatus;
    rejectionReason: string;
}

export enum CompanyType {
    OOO = 'OOO',
    OAO = 'OAO',
    ZAO = 'ZAO',
    Other = 'other',
}

export enum PublisherOfferAuthorisedRepresentativeDocType {
    Charter = 'charter',
    PowerOfAttorney = 'powerOfAttorney',
    Order = 'order',
    Disposition = 'disposition',
    RegulationsOnTheBranch = 'regulationsOnTheBranch',
}

export enum TaxationType {
    USN = 'USN',
    OSN = 'OSN',
}

export enum PublisherStatus {
    New = 'new',
    Rejected = 'rejected',
    Resolved = 'resolved',
    Run = 'run',
}

export interface ResponseFile {
    id: number;
    name: string;
}

export interface PublisherOffer {
    name: string;
    companyName: string;
    companyType: CompanyType;
    phone: string;
    partnershipType: PublisherOfferPartnershipType;
    siteName: string;
    siteStatisticUrl: string;
    placementFormats: PlacementFormat[];
    placementStands: PlacementStand[];
    inn: string;
    ogrn: string;
    orgnip: string;
    kpp: string;
    bik: string;
    checkingAccount: string;
    correspondentAccount: string;
    bankOgrn: string;
    authorisedRepresentativeName: string;
    authorisedRepresentativePosition: string;
    authorisedRepresentativeDocType: PublisherOfferAuthorisedRepresentativeDocType;
    authorisedRepresentativeDocNumber: string;
    authorisedRepresentativeDocDate: string;
    taxationType: TaxationType;
    contactPersonName: string;
    contactPersonEmail: string;
    contactPersonPhone: string;
    documentManagementContactPersonName: string;
    documentManagementContactPersonEmail: string;
    legalAddressRegion: string;
    legalAddressCity: string;
    legalAddressArea: string;
    legalAddressStreet: string;
    legalAddressBuild: string;
    legalAddressIndex: string;
    mailAddressTheSameAsLegal?: boolean;
    mailAddressRegion?: string;
    mailAddressCity?: string;
    mailAddressArea?: string;
    mailAddressStreet?: string;
    mailAddressBuild?: string;
    mailAddressIndex?: string;
    themes?: PublisherTheme[];
    createDate?: string;
    status?: PublisherStatus;
    rejectionReason?: string;
    filesIds?: string[];
    noEdo?: boolean;
    edoOperator?: string;
    edoGuid?: string;
    saluteOfferAccept?: boolean;
    personalDataPolicyAccept?: boolean;
    passportNumber?: string;
    passportDate?: string;
    passportIssuedBy?: string;
}

export interface PublisherOfferListItem {
    id: number;
    name: string;
    companyName: string;
    companyType: CompanyType;
    themes: PublisherTheme[];
    createDate: string;
    status: PublisherStatus;
}

// POST https://d-iimg-admin.apps.scosd.sberdevices.ru/iip/api/v0/publisher-offer/:id/files body multipart/form-data Files[] response ResponseFile[]
// DELETE https://d-iimg-admin.apps.scosd.sberdevices.ru/iip/api/v0/publisher-offer/:id/files/:id

// POST https://d-iimg-admin.apps.scosd.sberdevices.ru/iip/api/v0/publisher-offer body PublisherOffer
// PUT https://d-iimg-admin.apps.scosd.sberdevices.ru/iip/api/v0/publisher-offer/:id body PublisherOffer
// GET https://d-iimg-admin.apps.scosd.sberdevices.ru/iip/api/v0/publisher-offer/:id response PublisherOffer
// POST https://d-iimg-admin.apps.scosd.sberdevices.ru/iip/api/v0/publisher-offer/:id/send-to-legal-checking
// POST https://d-iimg-admin.apps.scosd.sberdevices.ru/iip/api/v0/publisher-offer/:id/approve-legal-checking
// POST https://d-iimg-admin.apps.scosd.sberdevices.ru/iip/api/v0/publisher-offer/:id/set-user body User
// GET https://d-iimg-admin.apps.scosd.sberdevices.ru/iip/api/v0/publisher-offers?seach=someSearch&theme=PublisherOfferTheme[]&status=PublisherOfferStatus[] response PublisherOfferListItem[]
