import React from 'react';
import { Button, Checkbox, TextField, BodyS } from '@salutejs/plasma-web';
import { useWatch } from 'react-hook-form';
import { FieldsSection, MaskedField, Select } from 'components';
import styled, { css } from 'styled-components';
import {
    bikRegExp,
    emailRegExp,
    indexRegExp,
    innRegExp,
    kppRegExp,
    ksRegExp,
    mobileMediaQueries,
    ogrnIPRegExp,
    ogrnRegExp,
    passportNumberRegExp,
    phoneRegExp,
    rsRegExp,
    validateDate,
} from 'utils';
import { UseFormReturn } from 'react-hook-form/dist/types';
import {
    PublisherOffer,
    PublisherOfferAuthorisedRepresentativeDocType,
    PublisherOfferPartnershipType,
    TaxationType,
} from 'types';
import { companyTypes } from 'const';
import { Icon } from '@salutejs/plasma-icons';
import { paragraph } from '@salutejs/plasma-tokens-b2b';

const StyledForm = styled.form`
    display: flex;
    flex-flow: column;
    max-width: 980px;
    gap: 32px;
`;

const StyledCompanyNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;

    & > div:first-child {
        width: 150px;
    }

    & > label {
        width: calc(100% - 150px - 16px);
    }

    /* stylelint-disable */
    ${mobileMediaQueries(css`
        flex-flow: column;

        & > div:first-child,
        & > label {
            width: 100%;
        }
    `)}
`;

const StyledSubmit = styled(Button)`
    margin-right: auto;
    min-width: 185px;

    ${mobileMediaQueries(css`
        width: 100%;
    `)}
`;

const StyledEdoForm = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    gap: 12px;

    & button {
        margin-right: auto;
    }
`;

const StyledEdoDesc = styled(BodyS)`
    color: ${paragraph};
`;

const StyledPolicyAccept = styled.div`
    display: flex;
    flex-flow: column;
    gap: 16px;
`;

const StyledLink = styled.a`
    color: ${paragraph};
    text-decoration: underline;
`;

interface Item {
    label: string;
    value: string;
}

const authorisedRepresentativeDocTypesList: Item[] = [
    { label: 'Устав', value: PublisherOfferAuthorisedRepresentativeDocType.Charter },
    { label: 'Доверенность', value: PublisherOfferAuthorisedRepresentativeDocType.PowerOfAttorney },
    { label: 'Приказ', value: PublisherOfferAuthorisedRepresentativeDocType.Order },
    { label: 'Распоряжение', value: PublisherOfferAuthorisedRepresentativeDocType.Disposition },
    { label: 'Положение о филиале', value: PublisherOfferAuthorisedRepresentativeDocType.RegulationsOnTheBranch },
];

const taxationTypesList: Item[] = [
    { label: 'УСН', value: TaxationType.USN },
    { label: 'ОСН', value: TaxationType.OSN },
];

interface PublisherRegFormProps {
    form: UseFormReturn<PublisherOffer>;
    onSubmit?(): void;
}

export const PublisherRegForm: React.FC<PublisherRegFormProps> = ({
    form: {
        register,
        setValue,
        trigger,
        formState: { errors, isValid },
        control,
    },
    onSubmit,
}) => {
    const data = useWatch({ control });

    const setField = (field: keyof PublisherOffer, value: PublisherOffer[keyof PublisherOffer]) => {
        setValue(field, value);
        trigger(field);
    };

    return (
        <StyledForm onSubmit={onSubmit}>
            <FieldsSection title="1. Данные организации">
                {data.partnershipType === PublisherOfferPartnershipType.Legal && (
                    <TextField
                        label="ИНН"
                        placeholder="Идентификационный номер налогоплательщика"
                        {...register('inn', { required: true, pattern: innRegExp, shouldUnregister: true })}
                        status={errors.inn ? 'error' : undefined}
                        onBlur={() => trigger('inn')}
                    />
                )}
                <StyledCompanyNameContainer>
                    <Select
                        label="Тип организации"
                        placeholder="Выберите"
                        {...register('companyType', { required: true })}
                        value={data.companyType}
                        items={companyTypes}
                        onChange={(value) => setField('companyType', value)}
                        status={errors.companyType ? 'error' : undefined}
                        onBlur={() => trigger('companyType')}
                    />
                    <TextField
                        label="Название"
                        placeholder="Организация"
                        {...register('companyName', { required: true })}
                        status={errors.companyName ? 'error' : undefined}
                        onBlur={() => trigger('companyName')}
                    />
                </StyledCompanyNameContainer>
                {data.partnershipType === PublisherOfferPartnershipType.IndividualEntrepreneur && (
                    <>
                        <TextField
                            label="ИНН"
                            placeholder="ИНН"
                            {...register('inn', { required: true, pattern: innRegExp, shouldUnregister: true })}
                            status={errors.inn ? 'error' : undefined}
                            onBlur={() => trigger('inn')}
                        />
                        <TextField
                            label="ОРГНИП"
                            placeholder="ОРГНИП"
                            {...register('orgnip', { required: true, pattern: ogrnIPRegExp, shouldUnregister: true })}
                            status={errors.orgnip ? 'error' : undefined}
                            onBlur={() => trigger('orgnip')}
                        />
                    </>
                )}
                {data.partnershipType === PublisherOfferPartnershipType.Legal && (
                    <>
                        <TextField
                            label="ОГРН"
                            placeholder="Основной государственный регистрационный номер"
                            {...register('ogrn', { required: true, pattern: ogrnRegExp, shouldUnregister: true })}
                            status={errors.ogrn ? 'error' : undefined}
                            onBlur={() => trigger('ogrn')}
                        />
                        <TextField
                            label="КПП"
                            placeholder="Код причины постановки на учёт"
                            {...register('kpp', { required: true, pattern: kppRegExp, shouldUnregister: true })}
                            status={errors.kpp ? 'error' : undefined}
                            onBlur={() => trigger('kpp')}
                        />
                    </>
                )}
            </FieldsSection>
            <FieldsSection title="2. Платежная информация">
                {data.partnershipType === PublisherOfferPartnershipType.Legal && (
                    <TextField
                        label="БИК"
                        placeholder="Банковский идентификационный код"
                        {...register('bik', { required: true, pattern: bikRegExp, shouldUnregister: true })}
                        status={errors.bik ? 'error' : undefined}
                        onBlur={() => trigger('bik')}
                    />
                )}
                <TextField
                    label="Р/С"
                    placeholder="Расчётный счёт"
                    {...register('checkingAccount', { required: true, pattern: rsRegExp })}
                    status={errors.checkingAccount ? 'error' : undefined}
                    onBlur={() => trigger('checkingAccount')}
                />
                <TextField
                    label="К/С"
                    placeholder="Корреспондентский счёт"
                    {...register('correspondentAccount', { required: true, pattern: ksRegExp })}
                    status={errors.correspondentAccount ? 'error' : undefined}
                    onBlur={() => trigger('correspondentAccount')}
                />
                <TextField
                    label="Банк"
                    placeholder="Введите ОГРН"
                    {...register('bankOgrn', { required: true, pattern: ogrnRegExp })}
                    status={errors.bankOgrn ? 'error' : undefined}
                    onBlur={() => trigger('bankOgrn')}
                />
                {data.partnershipType === PublisherOfferPartnershipType.IndividualEntrepreneur && (
                    <>
                        <MaskedField
                            label="Паспорт"
                            placeholder="Серия и номер паспорта"
                            mask="99 99 999999"
                            {...register('passportNumber', {
                                required: true,
                                pattern: passportNumberRegExp,
                                shouldUnregister: true,
                            })}
                            value={data.passportNumber}
                            onChange={(value) => setValue('passportNumber', value)}
                            status={errors.passportNumber ? 'error' : undefined}
                            onBlur={() => trigger('passportNumber')}
                        />
                        <TextField
                            label="Кем выдан"
                            placeholder="Кем выдан"
                            {...register('passportIssuedBy', { required: true, shouldUnregister: true })}
                            status={errors.passportIssuedBy ? 'error' : undefined}
                            onBlur={() => trigger('passportIssuedBy')}
                        />
                        <MaskedField
                            label="Дата выдачи"
                            placeholder="10.10.1994"
                            mask="99.99.9999"
                            {...register('passportDate', {
                                required: true,
                                shouldUnregister: true,
                                validate: validateDate as any,
                            })}
                            status={errors.passportDate ? 'error' : undefined}
                            value={data.passportDate}
                            onChange={(value) => setValue('passportDate', value)}
                            onBlur={() => trigger('passportDate')}
                        />
                    </>
                )}
            </FieldsSection>
            <FieldsSection title="3. Уполномоченный представитель">
                <TextField
                    label="Полное имя"
                    placeholder="Фамилия Имя Отчество"
                    {...register('authorisedRepresentativeName', { required: true })}
                    status={errors.authorisedRepresentativeName ? 'error' : undefined}
                    onBlur={() => trigger('authorisedRepresentativeName')}
                />
                {data.partnershipType === PublisherOfferPartnershipType.Legal && (
                    <>
                        <TextField
                            label="Должность"
                            placeholder="Менеджер"
                            {...register('authorisedRepresentativePosition', {
                                required: true,
                                shouldUnregister: true,
                            })}
                            status={errors.authorisedRepresentativePosition ? 'error' : undefined}
                            onBlur={() => trigger('authorisedRepresentativePosition')}
                        />
                        <Select
                            label="Документ"
                            placeholder="Выберите документ"
                            {...register('authorisedRepresentativeDocType', { required: true, shouldUnregister: true })}
                            value={data.authorisedRepresentativeDocType}
                            items={authorisedRepresentativeDocTypesList}
                            onChange={(value) => setField('authorisedRepresentativeDocType', value)}
                            status={errors.authorisedRepresentativeDocType ? 'error' : undefined}
                            onBlur={() => trigger('authorisedRepresentativeDocType')}
                        />
                        <TextField
                            label="Номер документа"
                            placeholder="Номер документа"
                            {...register('authorisedRepresentativeDocNumber', {
                                required: true,
                                shouldUnregister: true,
                            })}
                            status={errors.authorisedRepresentativeDocNumber ? 'error' : undefined}
                            onBlur={() => trigger('authorisedRepresentativeDocNumber')}
                        />
                        <MaskedField
                            label="Дата"
                            placeholder="дд.мм.гггг"
                            mask="99.99.9999"
                            {...register('authorisedRepresentativeDocDate', {
                                required: true,
                                shouldUnregister: true,
                                validate: validateDate as any,
                            })}
                            value={data.authorisedRepresentativeDocDate}
                            onChange={(value) => setValue('authorisedRepresentativeDocDate', value)}
                            status={errors.authorisedRepresentativeDocDate ? 'error' : undefined}
                            onBlur={() => trigger('authorisedRepresentativeDocDate')}
                        />
                    </>
                )}
            </FieldsSection>
            <FieldsSection title="4. Тип налогообложения">
                <Select
                    label="Тип налогообложения"
                    placeholder="Выберите тип налогообложения"
                    {...register('taxationType', { required: true })}
                    value={data.taxationType}
                    items={taxationTypesList}
                    onChange={(value) => setField('taxationType', value)}
                    status={errors.taxationType ? 'error' : undefined}
                    onBlur={() => trigger('taxationType')}
                />
            </FieldsSection>
            <FieldsSection title="5. Контактное лицо">
                <TextField
                    label="Полное имя"
                    placeholder="Фамилия Имя Отчество"
                    {...register('contactPersonName', { required: true })}
                    status={errors.contactPersonName ? 'error' : undefined}
                    onBlur={() => trigger('contactPersonName')}
                />
                <TextField
                    label="Email"
                    placeholder="mail@mail.ru"
                    {...register('contactPersonEmail', { required: true, pattern: emailRegExp })}
                    status={errors.contactPersonEmail ? 'error' : undefined}
                    onBlur={() => trigger('contactPersonEmail')}
                />
                <MaskedField
                    label="Телефон"
                    placeholder="+7 (999) 000 00-00"
                    mask="+7 (999) 999 99-99"
                    {...register('contactPersonPhone', { required: true, pattern: phoneRegExp })}
                    value={data.contactPersonPhone}
                    onChange={(value) => setValue('contactPersonPhone', value)}
                    status={errors.contactPersonPhone ? 'error' : undefined}
                    onBlur={() => trigger('contactPersonPhone')}
                />
            </FieldsSection>
            <FieldsSection title="6. Контактное лицо для документооборота">
                <TextField
                    label="Полное имя"
                    placeholder="Фамилия Имя Отчество"
                    {...register('documentManagementContactPersonName', { required: true })}
                    status={errors.documentManagementContactPersonName ? 'error' : undefined}
                    onBlur={() => trigger('documentManagementContactPersonName')}
                />
                <TextField
                    label="Email"
                    placeholder="mail@mail.ru"
                    {...register('documentManagementContactPersonEmail', { required: true, pattern: emailRegExp })}
                    status={errors.documentManagementContactPersonEmail ? 'error' : undefined}
                    onBlur={() => trigger('documentManagementContactPersonEmail')}
                />
            </FieldsSection>
            <FieldsSection title="7. Юридический адрес">
                <TextField
                    label="Регион"
                    placeholder="Москва"
                    {...register('legalAddressRegion', { required: true })}
                    status={errors.legalAddressRegion ? 'error' : undefined}
                    onBlur={() => trigger('legalAddressRegion')}
                />
                <TextField
                    label="Город"
                    placeholder="Москва"
                    {...register('legalAddressCity', { required: true })}
                    status={errors.legalAddressCity ? 'error' : undefined}
                    onBlur={() => trigger('legalAddressCity')}
                />
                <TextField
                    label="Район"
                    placeholder="Дорогомилово"
                    {...register('legalAddressArea', { required: true })}
                    status={errors.legalAddressArea ? 'error' : undefined}
                    onBlur={() => trigger('legalAddressArea')}
                />
                <TextField
                    label="Улица"
                    placeholder="Кутузовский проспект"
                    {...register('legalAddressStreet', { required: true })}
                    status={errors.legalAddressStreet ? 'error' : undefined}
                    onBlur={() => trigger('legalAddressStreet')}
                />
                <TextField
                    label="Здание (дом, корпус, строение, офис)"
                    placeholder="32"
                    {...register('legalAddressBuild', { required: true })}
                    status={errors.legalAddressBuild ? 'error' : undefined}
                    onBlur={() => trigger('legalAddressBuild')}
                />
                <TextField
                    label="Индекс"
                    placeholder="121170"
                    {...register('legalAddressIndex', { required: true, pattern: indexRegExp, maxLength: 6 })}
                    status={errors.legalAddressIndex ? 'error' : undefined}
                    onBlur={() => trigger('legalAddressIndex')}
                />
            </FieldsSection>
            <FieldsSection title="8. Почтовый адрес">
                <Checkbox label="Совпадает с юридическим адресом" {...register('mailAddressTheSameAsLegal')} />
                {!data.mailAddressTheSameAsLegal && (
                    <>
                        <TextField
                            label="Регион"
                            placeholder="Москва"
                            {...register('mailAddressRegion', { required: true, shouldUnregister: true })}
                            status={errors.mailAddressRegion ? 'error' : undefined}
                            onBlur={() => trigger('mailAddressRegion')}
                        />
                        <TextField
                            label="Город"
                            placeholder="Москва"
                            {...register('mailAddressCity', { required: true, shouldUnregister: true })}
                            status={errors.mailAddressCity ? 'error' : undefined}
                            onBlur={() => trigger('mailAddressCity')}
                        />
                        <TextField
                            label="Район"
                            placeholder="Дорогомилово"
                            {...register('mailAddressArea', { required: true, shouldUnregister: true })}
                            status={errors.mailAddressArea ? 'error' : undefined}
                            onBlur={() => trigger('mailAddressArea')}
                        />
                        <TextField
                            label="Улица"
                            placeholder="Кутузовский проспект"
                            {...register('mailAddressStreet', { required: true, shouldUnregister: true })}
                            status={errors.mailAddressStreet ? 'error' : undefined}
                            onBlur={() => trigger('mailAddressStreet')}
                        />
                        <TextField
                            label="Здание (дом, корпус, строение, офис)"
                            placeholder="32"
                            {...register('mailAddressBuild', { required: true, shouldUnregister: true })}
                            status={errors.mailAddressBuild ? 'error' : undefined}
                            onBlur={() => trigger('mailAddressBuild')}
                        />
                        <TextField
                            label="Индекс"
                            placeholder="121170"
                            {...register('mailAddressIndex', {
                                required: true,
                                pattern: indexRegExp,
                                shouldUnregister: true,
                                maxLength: 6,
                            })}
                            status={errors.mailAddressIndex ? 'error' : undefined}
                            onBlur={() => trigger('mailAddressIndex')}
                        />
                    </>
                )}
            </FieldsSection>
            <FieldsSection title="9. Использование ЭДО">
                <Checkbox label="Нет оператора ЭДО" {...register('noEdo', { required: true })} />
                {!data.noEdo && (
                    <>
                        <TextField
                            label="Оператор ЭДО"
                            placeholder="Введите оператора ЭДО"
                            {...register('edoOperator', { required: true, shouldUnregister: true })}
                            status={errors.edoOperator ? 'error' : undefined}
                            onBlur={() => trigger('edoOperator')}
                        />
                        <TextField
                            label="Идентификатор участника ЭДО (GUID)"
                            placeholder="Введите идентификатор"
                            {...register('edoGuid', { required: true, shouldUnregister: true })}
                            status={errors.edoGuid ? 'error' : undefined}
                            onBlur={() => trigger('edoGuid')}
                        />
                        <StyledEdoForm>
                            <StyledEdoDesc>
                                Пожалуйста, скачайте форму, заполните и отправьте нам. <br />
                                Все реквизиты будут внутри формы.
                            </StyledEdoDesc>
                            <Button contentLeft={<Icon icon="download" />} text="Скачать форму ЭДО" />
                        </StyledEdoForm>
                    </>
                )}
            </FieldsSection>
            {onSubmit && (
                <>
                    <StyledPolicyAccept>
                        <Checkbox
                            label={
                                <>
                                    Ознакомился, согласен и полностью принимаю условия{' '}
                                    <StyledLink
                                        href="https://developers.sber.ru/docs/ru/compliance/consent-sd"
                                        target="_blank"
                                    >
                                        Оферты СалютДевайсы
                                    </StyledLink>{' '}
                                </>
                            }
                            {...register('saluteOfferAccept', { required: true })}
                        />
                        <Checkbox
                            label={
                                <>
                                    Даю{' '}
                                    <StyledLink
                                        href="https://developers.sber.ru/docs/ru/compliance/consent-sd"
                                        target="_blank"
                                    >
                                        согласие
                                    </StyledLink>{' '}
                                    на обработку и хранение моих персональных данных
                                </>
                            }
                            {...register('personalDataPolicyAccept', { required: true })}
                        />
                    </StyledPolicyAccept>
                    <StyledSubmit view="primary" type="submit" disabled={!isValid}>
                        Отправить
                    </StyledSubmit>
                </>
            )}
        </StyledForm>
    );
};
