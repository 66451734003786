import { AppStand } from '@/types';
import { getAppStand } from '@/utils';

const appStand = getAppStand();

export const API_URL = `/iimg-admin-${appStand}`;
export enum API_ENDPOINT {
    CLICKS = 'clicked',
    PRICE_AVG = 'average-price',
    VIEWS_TOTAL = 'total-showing',
    SMART_BANNER_VIEWS_TOTAL = 'banner-total-showing',
    VIEWS_UNIQUE = 'unique-showing',
    SUMMARY = 'summary',
    EXPENSES = 'outlay',
    CTR = 'ctr',
    PRODUCTS_STAT = 'products-stat',
    SITES = 'sites',
    FEATURES = 'partner-user-properties',
}

export enum API_PERIOD {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    QUARTERLY = 'quarterly',
    YEARLY = 'yearly',
}

export const getApiBaseUrl = (stand: string = AppStand.prom) =>
    ({
        [AppStand.ift]: 'https://d-iimg-admin.apps.scosd.sberdevices.ru/iip/api/v0',
        [AppStand.prom]: 'https://p-iimg-admin.apps.ssp.sberdevices.ru/iip/api/v0',
    }[stand]);

export const PER_PAGE: Record<'products' | 'objects', number> = {
    products: 200,
    objects: 200,
};

export const MINUTES_IN_SCENES_PAGE = 2;

export const GENDERS = [
    { value: 'male', label: 'Мужской' }, // 1
    { value: 'female', label: 'Женский' }, // 2
    { value: 'boy', label: 'Мальчик' }, // 3
    { value: 'girl', label: 'Девочка' }, // 4
    { value: 'undefined', label: 'Неопределенный' }, // 20
    { value: 'unisex', label: 'Универсальный' }, // 23
];

// http://p-wh.apps.ssp.sberdevices.ru/wh/api/v0/catalog/
export const CATEGORIES = [
    { value: 'accessories', label: 'Аксессуары' },
    { value: 'clothes', label: 'Одежда' },
    { value: 'shoes', label: 'Обувь' },
];
