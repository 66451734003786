import { useCallback, useMemo, useState } from 'react';
import { PersonalOffer } from 'types/PersonalOffer';

const lsKey = 'personal-offer';

interface PersonalOfferHookResult {
    offer: Partial<PersonalOffer>;
    update(offer: Partial<PersonalOffer>): void;
}

export const usePersonalOffer = (): PersonalOfferHookResult => {
    const [offer, setOffer] = useState<PersonalOfferHookResult['offer']>(
        typeof window !== 'undefined' ? JSON.parse(localStorage?.getItem(lsKey) ?? '{}') : {},
    );

    const update = useCallback<PersonalOfferHookResult['update']>(
        (newOffer) =>
            setOffer((oldOffer) => {
                const newState = { ...oldOffer, ...newOffer };

                localStorage.setItem(lsKey, JSON.stringify(newState));

                return newState;
            }),
        [],
    );

    return useMemo<PersonalOfferHookResult>(() => ({ offer, update }), [offer, update]);
};
