import React, { FC } from 'react';
import styled from 'styled-components';
import { Header, HeaderNavItem, StandSwitcher } from 'components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 600px;
    position: relative;
    height: 100%;
    padding-bottom: 64px;
`;

interface LayoutProps {
    nav?: HeaderNavItem[];
}

export const Layout: FC<LayoutProps> = ({ nav, children }) => {
    return (
        <Wrapper>
            <Header nav={nav} />
            {children}
            <StandSwitcher />
        </Wrapper>
    );
};
