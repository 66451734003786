import { addNotification } from '@salutejs/plasma-web';

const notificationDuration = 3000;

const createMessage = (title: string, status: 'success' | 'warning' | 'error', description?: string) => {
    return addNotification(
        {
            status,
            title,
            children: description || '',
        },
        notificationDuration,
    );
};

export const showSuccessNotification = (title: string, description?: string) => {
    return createMessage(title, 'success', description);
};

export const showErrorNotification = (title: string, description?: string) => {
    return createMessage(title, 'error', description);
};

export const showWarningNotification = (title: string, description?: string) => {
    return createMessage(title, 'warning', description);
};
