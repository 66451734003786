export const dateFormatter = new Intl.DateTimeFormat('ru', { day: '2-digit', month: 'short', year: 'numeric' });

export const periodFormat = (start: string, end: string): string => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const sameYear = startDate.getFullYear() === endDate.getFullYear();
    const sameMonth = startDate.getMonth() === endDate.getMonth();
    let formattedStart = dateFormatter.format(startDate).replace(/(\sг?)?\./gi, '');
    const formattedEnd = dateFormatter.format(endDate).replace(/(\sг?)?\./gi, '');
    let separator = ' – ';

    if (sameYear && !sameMonth) {
        formattedStart = formattedStart.replace(/\s\d+$/, '');
    }

    if (sameYear && sameMonth) {
        formattedStart = formattedStart.replace(/^(\d+).+/, '$1');
        separator = '–';
    }

    return `${formattedStart}${separator}${formattedEnd}`;
};
