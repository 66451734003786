import React, { useCallback, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { BodyM } from '@salutejs/plasma-web';
import { Icon } from '@salutejs/plasma-icons';
import { useBool } from 'hooks';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { bodyM } from '@salutejs/plasma-typo';
import { buttonPrimary, tertiary } from '@salutejs/plasma-tokens-b2b';
import { surfaceLiquid03 } from '@salutejs/plasma-tokens-b2b/colors';

const StyledContainer = styled.div`
    position: relative;
`;

const StyledHeader = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

const StyledValue = styled(BodyM)`
    position: relative;
    display: flex;
    flex-flow: row;
    align-items: center;
`;

const StyledList = styled.div`
    position: absolute;
    top: 100%;
    left: -16px;
    right: -16px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 1px 2px 20px 0 rgba(0, 0, 0, 0.12), 1px 1px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 8px;
    margin-top: 12px;
    z-index: 1;
`;

const StyledItem = styled.div<{ topBorder?: boolean; bottomBorder?: boolean }>`
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 4px;
    padding: 6px 12px;
    cursor: pointer;
    ${bodyM}

    ${({ topBorder }) =>
        topBorder &&
        css`
            border-top: solid 1px ${surfaceLiquid03};
        `}

    &:hover {
        background: #fafafa;
    }
`;

const StyledItemText = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 4px;
    margin-right: auto;
`;

export interface Item {
    label: React.ReactNode;
    value: any;
    selectable?: boolean;
    topBorder?: boolean;
    bottomBorder?: boolean;
    canDelete?: boolean;
}

export interface ActiveItem extends Item {
    active: boolean;
}

export interface TableMultiSelectProps {
    items: Item[];
    value: any[];
    onChange(values: any[]): void;
    onItemClick?(item: Item): void;
    onDelete?(item: Item): void;
}

export const TableMultiSelect: React.FC<TableMultiSelectProps> = ({
    items,
    value,
    onChange,
    onItemClick,
    onDelete,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const localItems = useMemo<ActiveItem[]>(
        () => items.map((item) => ({ ...item, active: value.includes(item.value) })),
        [items, value],
    );
    const { value: open, off, toggle } = useBool();
    const valueText = useMemo<string>(
        () =>
            localItems
                .filter((item) => item.active)
                .reduce<string>((acc, item) => (acc ? `${acc}, ${item.label}` : item.label) as string, ''),
        [localItems],
    );

    const itemClickHandle = useCallback(
        (item: Item) => {
            onItemClick?.(item);

            if (item.selectable === false) {
                return;
            }

            const newItems = localItems.map((listItem) =>
                listItem.value === item.value ? { ...listItem, active: !listItem.active } : listItem,
            );

            onChange(newItems.filter((listItem) => listItem.active).map((listItem) => listItem.value));
        },
        [localItems, onChange, onItemClick],
    );

    useOnClickOutside(containerRef.current, off);

    return (
        <StyledContainer ref={containerRef}>
            <StyledHeader onClick={toggle}>
                <StyledValue>{valueText}</StyledValue>
                <Icon icon="chevronDown" size="xs" />
            </StyledHeader>
            {open && (
                <StyledList>
                    {localItems.map((item, index) => (
                        <StyledItem
                            key={index}
                            onClick={() => itemClickHandle(item)}
                            topBorder={item.topBorder}
                            bottomBorder={item.bottomBorder}
                        >
                            <StyledItemText>{item.label}</StyledItemText>
                            {item.active && <Icon icon="done" color={buttonPrimary} />}
                            {item.canDelete && (
                                <div onClick={() => onDelete?.(item)}>
                                    <Icon icon="trash" color={tertiary} />
                                </div>
                            )}
                        </StyledItem>
                    ))}
                </StyledList>
            )}
        </StyledContainer>
    );
};
