import { FormEvent, useEffect, useState } from 'react';
import { Button, Headline2, Headline5, TextField } from '@salutejs/plasma-web';
import { SpacingBlock } from 'components/SpacingBlock';
import styled from 'styled-components';

import { minWidth } from './FlexContainer';

export const Form = styled.form`
    position: relative;
    background: #fff;
    width: 544px;
    height: 544px;
    border-radius: 16px;
    padding: 64px 44px;
    margin-left: 7rem;

    @media (max-width: ${minWidth}px) {
        width: 404px;
    }
`;

const Logo = styled.div`
    width: 120px;
    height: 32px;
    background-image: url('/assets/icons/logo.svg');
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 24px;
`;

type Props = {
    onSubmit: (username: string, password: string, handleAfterSubmit: (isValid: boolean) => void) => void;
};

export const LoginForm = ({ onSubmit }: Props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [canSubmit, setCanSubmit] = useState(false);

    const handleAfterSubmit = (isAuthorized: boolean) => {
        setCanSubmit(!isAuthorized);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCanSubmit(false);
        onSubmit(username, password, handleAfterSubmit);
    };

    useEffect(() => {
        setCanSubmit(username !== '' && password !== '');
    }, [username, password]);

    return (
        <Form onSubmit={handleSubmit}>
            <Logo />
            <Headline2 mb="12x">
                Войдите в свою <br />
                учетную запись
            </Headline2>
            <SpacingBlock mb="12x">
                <SpacingBlock mb="4x">
                    <Headline5>Введите логин</Headline5>
                </SpacingBlock>
                <TextField
                    value={username}
                    onChange={(e) => {
                        setUsername(e.target.value);
                    }}
                    placeholder="Введите email"
                    autoComplete="email"
                    name="email"
                    required
                    type="email"
                />
            </SpacingBlock>

            <SpacingBlock mb="12x">
                <SpacingBlock mb="4x">
                    <Headline5>Введите пароль</Headline5>
                </SpacingBlock>
                <TextField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Введите пароль"
                    autoComplete="password"
                    name="password"
                    required
                    type="password"
                />
            </SpacingBlock>

            <Button disabled={!canSubmit} type="submit" stretch size="m" pin="square-square" view="primary">
                Войти
            </Button>
        </Form>
    );
};
