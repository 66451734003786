import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { accent } from '@salutejs/plasma-tokens-b2b';
import { Button, Caption } from '@salutejs/plasma-web';
import { currentStandCookieName } from 'const';
import { AppStand } from 'types';
import { getAppStand, appStand } from 'utils';

import { dividerBorder } from '@/mixins';
import { setCookie } from '@/cookie';

const StandSwitcherWrapper = styled.div`
    display: flex;
    flex-flow: column;
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border: ${dividerBorder};
    overflow: hidden;
    background-color: #fff;
`;

const StyledButton = styled(Button)<{ isActive: boolean }>`
    padding: 4px;

    ${({ isActive }) =>
        isActive &&
        css`
            color: ${accent};
            cursor: default !important;
            opacity: 1 !important;
        `};
`;

interface StandButton {
    stand: AppStand;
    activeStand?: AppStand;
    onChange(stand: AppStand): void;
}

const StandButton: React.FC<StandButton> = ({ stand, activeStand, onChange }) => {
    const changeHandle = useCallback(() => onChange(stand), [onChange, stand]);

    return (
        <StyledButton
            view="clear"
            isActive={activeStand === stand}
            disabled={activeStand === stand}
            onClick={changeHandle}
        >
            <Caption>{stand.toUpperCase()}</Caption>
        </StyledButton>
    );
};

export const StandSwitcher: React.FC = () => {
    const [activeStand, setActiveStand] = useState<AppStand>();

    const onSetActiveStand = useCallback((stand: AppStand): void => {
        setCookie(currentStandCookieName, stand);
        window.location.reload();
    }, []);

    useEffect(() => {
        setActiveStand(getAppStand());
    }, []);

    if (appStand === 'prom') {
        return null;
    }

    return (
        <StandSwitcherWrapper>
            <StandButton stand={AppStand.prom} activeStand={activeStand} onChange={onSetActiveStand} />
            <StandButton stand={AppStand.ift} activeStand={activeStand} onChange={onSetActiveStand} />
        </StandSwitcherWrapper>
    );
};
